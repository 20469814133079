import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

export const ImageModal = ({
  modalOpen,
  setModalOpen,
  imageUrl,
}: {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  imageUrl: string;
}) => {
  const imageRef = useRef<HTMLImageElement>(null);

  return (
    <div>
      <Transition show={modalOpen} as={Fragment}>
        <Dialog initialFocus={imageRef} onClose={() => setModalOpen(false)}>
          {/* Modal backdrop */}
          <Transition.Child
            className="fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity"
            enter="transition ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            aria-hidden="true"
          />
          {/* End: Modal backdrop */}

          {/* Modal dialog */}
          <Transition.Child
            className="fixed inset-0 z-50 flex p-6"
            enter="transition ease-out duration-300"
            enterFrom="opacity-0 scale-75"
            enterTo="opacity-100 scale-100"
            leave="transition ease-out duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
          >
            <div className="max-w-5xl mx-auto h-full flex items-center justify-center z-50">
              <Dialog.Panel className="max-h-full max-w-full shadow-2xl bg-black">
                <img
                  ref={imageRef}
                  src={imageUrl}
                  className="max-w-full max-h-[90dvh] object-contain"
                  alt="Fullscreen Image"
                />
              </Dialog.Panel>
            </div>
          </Transition.Child>
          {/* End: Modal dialog */}
        </Dialog>
      </Transition>
    </div>
  );
};
