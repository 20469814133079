import { Header } from "./components/Header";
import { SecurityPolicy } from "./components/SecurityPolicy";
import { TermsOfService } from "./components/TermsOfService";
import { Hero } from "./components/Hero";
import { PrimaryFeatures } from "./components/PrimaryFeatures";
import { UseCases } from "./components/UseCases";
// import { SecondaryFeatures } from './components/SecondaryFeatures'; Not required for now
import { CallToAction } from "./components/CallToAction";
import { Testimonials } from "./components/Testimonials";
import { CaseStudy } from "./components/CaseStudy";
// import { Pricing } from './components/Pricing'; Not required for now
import { PricingPlans } from "./components/PricingPlans";
import { Faqs } from "./components/Faqs";
import { Footer } from "./components/Footer";
import { Route, Routes, Navigate } from "react-router-dom";
import { MergedTuskIssues } from "./components/MergedTuskIssues";
import { CaseStudyArticle } from "./components/CaseStudyArticle";

export const MarketingPage = () => {
  // all the stuff from index.jsx
  // Footer
  return (
    <>
      <Header />
      <Routes>
        <Route path="/security" element={<Navigate replace to="/privacy" />} />
        <Route path="/privacy" element={<SecurityPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/hitpay-case" element={<CaseStudyArticle />} />
        <Route
          path="/"
          element={
            <>
              <Hero />
              <PrimaryFeatures />
              <UseCases />
              <CaseStudy />
              <MergedTuskIssues />
              {/* <SecondaryFeatures /> */}
              <Testimonials />
              {/* <Pricing /> */}
              <PricingPlans />
              <Faqs />
              <CallToAction />
            </>
          }
        />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Footer />
    </>
  );
};
