import { BoltIcon, DocumentTextIcon } from "@heroicons/react/20/solid";
import { Button } from "./Button";

interface IProps {
  onNewIssueClick: () => void;
  buttonDisabled?: boolean;
  buttonTooltip?: string;
}

export const IssueEmptyState = ({
  onNewIssueClick,
  buttonDisabled = false,
  buttonTooltip = "",
}: IProps) => {
  return (
    <div className="text-center px-48 py-16 rounded-lg border border-gray-300">
      {/* <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg> */}
      <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No issues yet</h3>
      <p className="mt-2 text-sm text-gray-500">
        Create a new issue or{" "}
        <a
          href="https://docs.usetusk.ai/ticketing-integrations"
          className="text-purple-600 hover:text-purple-800"
          target="_blank"
          rel="noopener noreferrer"
        >
          assign an existing issue
        </a>
        .
      </p>
      <p className="text-sm text-gray-500">Try a copy change to get started.</p>
      <div className="mt-6">
        <Button
          size="lg"
          type="button"
          onClick={onNewIssueClick}
          disabled={buttonDisabled}
          tooltipText={buttonTooltip}
        >
          {/* <BoltIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" /> */}
          Get started
        </Button>
      </div>
    </div>
  );
};
