import { GetTasksQuery, GetTaskWithAllRunsQuery } from "../__generatedGQL__/graphql";

export enum TaskStatus {
  PendingInvestigation = "PENDING_INVESTIGATION",
  DoingInvestigation = "INVESTIGATING_ISSUE",
  FailedInvestigation = "INVESTIGATION_FAILED",
  CompletedInvestigation = "INVESTIGATION_COMPLETED",
  PendingPr = "PENDING_PR",
  GeneratingPr = "GENERATING_PR",
  FailedGeneratingPr = "FAILED_GENERATING_PR",
  DraftPrGenerated = "DRAFT_PR_GENERATED",
  PrGenerated = "PR_GENERATED",
  PendingBranch = "PENDING_BRANCH",
  GeneratingBranch = "GENERATING_BRANCH",
  FailedGeneratingBranch = "FAILED_GENERATING_BRANCH",
  BranchGenerated = "BRANCH_GENERATED",
  PrClosed = "PR_CLOSED",
  PrMerged = "PR_MERGED",
  PendingTestingPr = "PENDING_TESTING_PR",
  TestingPr = "TESTING_PR",
  FailedTestingPr = "FAILED_TESTING_PR",
  PrTested = "PR_TESTED",
  AddressingCodeReview = "ADDRESSING_CODE_REVIEW",
  AddressingCodeReviewFailed = "ADDRESSING_CODE_REVIEW_FAILED",
  Unknown = "UNKNOWN",
}

// TODO: This is a bit of a mess, but it's a start. Clean it up. Main challenge is that in theory a task can have multiple runs.
export const getTaskStatus = (
  task?: GetTasksQuery["tasks"][0] | GetTaskWithAllRunsQuery["task"],
): TaskStatus => {
  // If PR is closed or merged, that's the status since there isn't anything else to do
  const activePullRequest = task?.activeGeneratePullRequestRun?.activePullRequest;
  if (activePullRequest) {
    switch (activePullRequest.status) {
      case "CLOSED":
        return TaskStatus.PrClosed;
      case "MERGED":
        return TaskStatus.PrMerged;
      default:
        break;
    }
  }

  const activeInvestigationRun = task?.activeInvestigationRun;
  const activeTestingRun = task?.activeGeneratePullRequestRun?.activePullRequest?.activeTestingRun;
  const activeAddressCodeReviewRun =
    task?.activeGeneratePullRequestRun?.activePullRequest?.activeAddressCodeReviewRun;

  // Do running statuses first since they are happening at the moment
  if (activeAddressCodeReviewRun) {
    switch (activeAddressCodeReviewRun.status) {
      case "RUNNING":
        return TaskStatus.AddressingCodeReview;
      default:
        break;
    }
  }

  if (activeTestingRun) {
    switch (activeTestingRun.status) {
      case "RUNNING":
        return TaskStatus.TestingPr;
      default:
        break;
    }
  }

  if (activeAddressCodeReviewRun) {
    switch (activeAddressCodeReviewRun.status) {
      case "INTERNAL_FAILURE":
      case "SELF_FAILURE": // TODO-FAIL: have a separate status for this?
        return TaskStatus.AddressingCodeReviewFailed;
      case "COMPLETED":
        return TaskStatus.PrGenerated;
      default:
        break;
    }
  }

  if (activeTestingRun) {
    switch (activeTestingRun.status) {
      case "INTERNAL_FAILURE":
        return TaskStatus.FailedTestingPr;
      case "COMPLETED":
        return TaskStatus.PrTested;
      default:
        break;
    }
  }

  if (activePullRequest) {
    switch (activePullRequest.status) {
      case "OPEN":
        return TaskStatus.PrGenerated;
      case "DRAFT":
        return TaskStatus.DraftPrGenerated;
      default:
        break;
    }
  }

  const activeGeneratePullRequestRun = task?.activeGeneratePullRequestRun;
  if (activeGeneratePullRequestRun) {
    switch (activeGeneratePullRequestRun.status) {
      case "PENDING":
        return task?.metadata?.disablePullRequestCreation
          ? TaskStatus.PendingBranch
          : TaskStatus.PendingPr;
      case "RUNNING":
        return task?.metadata?.disablePullRequestCreation
          ? TaskStatus.GeneratingBranch
          : TaskStatus.GeneratingPr;
      case "INTERNAL_FAILURE":
      case "SELF_FAILURE": // TODO-FAIL: have a separate status for this?
        return task?.metadata?.disablePullRequestCreation
          ? TaskStatus.FailedGeneratingBranch
          : TaskStatus.FailedGeneratingPr;
      case "COMPLETED":
        return activeGeneratePullRequestRun?.activePullRequest
          ? TaskStatus.PrGenerated
          : TaskStatus.BranchGenerated;
      default:
        break;
    }
  }

  if (activeInvestigationRun) {
    switch (activeInvestigationRun.status) {
      case "PENDING":
        return TaskStatus.PendingInvestigation;
      case "RUNNING":
        return TaskStatus.DoingInvestigation;
      case "INTERNAL_FAILURE":
      case "SELF_FAILURE":
        return TaskStatus.FailedInvestigation;
      case "COMPLETED":
        return TaskStatus.CompletedInvestigation;
      default:
        break;
    }
  }

  return TaskStatus.Unknown;
};

export const getGeneratePullRequestRunBranchUrl = (
  task?: GetTasksQuery["tasks"][0] | GetTaskWithAllRunsQuery["task"],
): string | undefined => {
  if (!task?.repo) {
    return undefined;
  }

  if (!task?.activeGeneratePullRequestRun?.branchName) {
    return undefined;
  }

  return `https://github.com/${task?.repo?.ownerName}/${task?.repo?.name}/compare/${task?.repo?.defaultBranch}...${task?.activeGeneratePullRequestRun?.branchName}`;
};
