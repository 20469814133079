import _extends from "@babel/runtime/helpers/extends";
import { useEffect } from 'react';
import { useSymbolsDispatch } from '../store/Symbols';
import { useTypesDispatch } from '../store/Types';
import { useSectionDispatch } from '../store/Section';
export function useSymbolsRender(currentProps, props, key) {
  var dispatch = useSymbolsDispatch();
  var cls = [currentProps.className, props.className].filter(Boolean).join(' ');
  var reset = _extends({}, currentProps, props, {
    className: cls,
    style: _extends({}, currentProps.style, props.style),
    children: props.children || currentProps.children
  });
  useEffect(() => dispatch({
    [key]: reset
  }), [props]);
}
export function useTypesRender(currentProps, props, key) {
  var dispatch = useTypesDispatch();
  var cls = [currentProps.className, props.className].filter(Boolean).join(' ');
  var reset = _extends({}, currentProps, props, {
    className: cls,
    style: _extends({}, currentProps.style, props.style),
    children: props.children || currentProps.children
  });
  useEffect(() => dispatch({
    [key]: reset
  }), [props]);
}
export function useSectionRender(currentProps, props, key) {
  var dispatch = useSectionDispatch();
  var cls = [currentProps.className, props.className].filter(Boolean).join(' ');
  var reset = _extends({}, currentProps, props, {
    className: cls,
    style: _extends({}, currentProps.style, props.style),
    children: props.children || currentProps.children
  });
  useEffect(() => dispatch({
    [key]: reset
  }), [props]);
}