import { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { clsx } from "clsx";

import { Container } from "../components/Container";

import backgroundImage from "../images/background-purple.png";
import imageAutomatedSanityTests from "../images/image-automated-sanity-tests.png";
import imageFailsGracefully from "../images/image-fails-gracefully.png";

const features = [
  {
    title: "One-click push",
    description:
      "Add the ‘Tusk’ label to a Linear, Jira, Notion, or GitHub issue and we’ll push code to your GitHub repo in minutes.",
    image: "https://storage.googleapis.com/use-tusk-dev-static/linear_ticket_to_pr_demo.mp4",
  },
  {
    title: "Addresses feedback",
    description:
      "Tusk automatically iterates on its code changes to address any code reviews left on the pull request.",
    image: "https://storage.googleapis.com/use-tusk-dev-static/address_pr_review_demo.mp4",
  },
  {
    title: "Runs automated checks",
    description:
      "No good engineer pushes code without testing. Tusk runs your automated checks and tests to make sure the PR works.",
    image: "https://storage.googleapis.com/use-tusk-dev-static/tusk_gh_workflow_demo.mp4",
  },
  {
    title: "Investigates complex tasks",
    description:
      "If we can’t create a pull request, we’ll still save you time by providing code context as a jumping-off point.",
    image: "https://storage.googleapis.com/use-tusk-dev-static/fails_gracefully_demo.mp4",
  },
];

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState("horizontal");

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? "vertical" : "horizontal");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-purple-600 pb-28 pt-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none w-full -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
      />
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Generate code from ticket to pull request.
          </h2>
          <p className="mt-6 text-xl tracking-tight text-purple-100">
            Tusk helps software engineers complete chore tickets at the speed of thought.
          </p>
        </div>
        <Tab.Group
          as="div"
          className="mt-16 sm:mt-4 grid grid-cols-1 items-center gap-y-2 lg:pt-10 sm:gap-y-6 md:mt-8 lg:grid-cols-12"
          vertical={tabOrientation === "vertical"}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        "group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6",
                        selectedIndex === featureIndex
                          ? "bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
                          : "hover:bg-white/10 lg:hover:bg-white/5",
                      )}
                    >
                      <h3>
                        <Tab
                          className={clsx(
                            "font-display text-lg focus:outline-none",
                            selectedIndex === featureIndex
                              ? "text-purple-600 lg:text-white"
                              : "text-purple-100 hover:text-white lg:text-white",
                          )}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          "mt-2 hidden text-sm lg:block",
                          selectedIndex === featureIndex
                            ? "text-white"
                            : "text-purple-100 group-hover:text-white",
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      {/* <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] sm:inset-x-0 sm:rounded-t-xl" /> */}
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-purple-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                      <video
                        className="w-full lg:h-[40rem] xs:h-[20rem] object-cover"
                        src={feature.image}
                        loop
                        autoPlay
                        muted
                        playsInline
                      >
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  );
}
