import {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
  useSyncExternalStore,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuth0 } from "@auth0/auth0-react";
import { find, includes, isEmpty, size } from "lodash";
import { usePostHog } from "posthog-js/react";

import { getEnvironment } from "../utils";
import { gql } from "../__generatedGQL__/gql";
import { GetUserQuery } from "../__generatedGQL__/graphql";
import { LoadingSpinner, ErrorPage } from "../components";

export interface IAppContext {
  user?: GetUserQuery["user"];
  clients?: GetUserQuery["user"]["clients"];
  selectedClient?: GetUserQuery["user"]["clients"][0];
  selectedClientId?: string;
  setSelectedClientId?: (clientId: string) => void;
  isAuthenticated: boolean;
  isLoadingAppContext: boolean;
  authToken: string;
}

export const AppContext = createContext<IAppContext>({
  isAuthenticated: false,
  isLoadingAppContext: false,
  authToken: "",
  user: {} as GetUserQuery["user"],
  clients: [] as GetUserQuery["user"]["clients"],
  selectedClient: {} as GetUserQuery["user"]["clients"][0],
  selectedClientId: "",
  setSelectedClientId: (clientId: string) => {},
});

export const useAppContext = () => useContext(AppContext);

const GET_USER = gql(`
  query GetUser {
    user {
      id
      name
      email
      isAdmin
      clients {
        id
        name
        features
        subscriptionPlan {
          planName
          pricePerMonth
          maxSyncedRepos
          maxSuccessfulTasks
        }
      }
    }
  }
`);

const GET_CLIENT = gql(`
  query GetClient($id: String!) {
    client(id: $id) {
      id
      name
      features
      subscriptionPlan {
        planName
        pricePerMonth
        maxSyncedRepos
        maxSuccessfulTasks
      }
    }
  }
`);

const CREATE_USER = gql(`
  mutation CreateUser($name: String!, $email: String!) {
    createUser(name: $name, email: $email) {
      id
      name
      email
      isAdmin
      clients {
        id
        name
        features
        subscriptionPlan {
          planName
          pricePerMonth
          maxSyncedRepos
          maxSuccessfulTasks
        }
      }
    }
  }
`);

export const AppProvider = ({ children }) => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading: isAuthLoading,
    user: auth0User,
  } = useAuth0();

  const navigate = useNavigate();
  const posthog = usePostHog();

  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>(null);
  const [authToken, setAuthToken] = useState<string>(null);
  const [user, setUser] = useState<GetUserQuery["user"]>(null);
  const [selectedClient, setSelectedClient] = useState<GetUserQuery["user"]["clients"][0]>(null);
  const [selectedClientId, setSelectedClientId] = useState<string>(null);
  const [hasError, setHasError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("selectedClientId", selectedClientId);

  // this is the client object for a client that is not in the user's clients array, but client id is provided in the URL
  // only admins can access clients in this way (verified in the middleware)
  const [adminClient, setAdminClient] = useState<GetUserQuery["user"]["clients"][0]>(null);

  const allClients = adminClient ? [...user?.clients, adminClient] : user?.clients;

  useMemo(async () => {
    if (user && selectedClientId && selectedClient?.id !== selectedClientId) {
      const selectedClient = find(allClients, (client) => client.id === selectedClientId);
      if (selectedClient) {
        setSelectedClient(selectedClient);
      } else if (user?.isAdmin) {
        const adminClient = await apolloClient.query({
          query: GET_CLIENT,
          variables: {
            id: selectedClientId,
          },
        });
        setAdminClient(adminClient.data.client);
        setSelectedClient(adminClient.data.client);
      }
    }

    if (size(allClients) > 1 && selectedClientId !== searchParams.get("client")) {
      searchParams.set("client", selectedClientId);
      setSearchParams(searchParams);
    }
  }, [selectedClientId, allClients, searchParams, apolloClient, user?.isAdmin]);

  useEffect(() => {
    const urlClient = searchParams.get("client");
    if (!urlClient) {
      return;
    }

    if (urlClient === selectedClientId) {
      return;
    }

    const allClientIds = allClients?.map((client) => client.id);
    if (!isEmpty(allClientIds) && !includes(allClientIds, urlClient)) {
      console.log(`Client ID ${urlClient} not found`);
      return;
    }

    console.log("Setting selected client ID from URL", urlClient);
    setSelectedClientId(urlClient);
  }, [searchParams, allClients]);

  useEffect(() => {
    if (!isAuthenticated) {
      console.log("User is not authenticated, returning. Current token:", authToken);
      return;
    }

    if (!authToken) {
      console.log("No auth token, returning");
      return;
    }

    console.log("Setting up GraphQL with auth token:", authToken);

    const httpLink = createHttpLink({
      uri: `${process.env.API_BASE_URL}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: authToken ? `Bearer ${authToken}` : "",
          ...(selectedClientId ? { "selected-client-id": selectedClientId } : {}),
        },
      };
    }).concat(httpLink);

    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err.extensions?.code) {
            case "UNAUTHORIZED":
              console.log("UNAUTHORIZED, trying to get new token. Current token:", authToken);
              return fromPromise(
                getAccessTokenSilently()
                  .then((newToken) => {
                    console.log("Refreshed token. New token:", newToken);
                    setAuthToken(newToken);
                    // Do this so that the new token is used in the retry
                    operation.setContext(({ headers = {} }) => ({
                      headers: {
                        ...headers,
                        authorization: `Bearer ${newToken}`,
                      },
                    }));
                    return operation;
                  })
                  .catch((err) => {
                    console.log("Error getting new token", err);
                    setHasError(true);
                    return operation;
                  }),
              ).flatMap(forward);
          }
        }
      }
    });

    const apolloClient = new ApolloClient({
      link: from([errorLink, authLink]),
      cache: new InMemoryCache(),
    });

    setApolloClient(apolloClient);
  }, [authToken, isAuthenticated, selectedClientId, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthLoading) {
      return;
    }

    if (isAuthenticated) {
      console.log("User is authenticated, getting access token. Current token:", authToken);
      getAccessTokenSilently()
        .then((token) => {
          console.log("First time getting token:", token);
          if (token !== authToken) {
            posthog?.capture("user_logged_in");
          }
          setAuthToken(token);
        })
        .catch((err) => {
          console.log("Error setting up GraphQL", err);
          setHasError(true);
        });
    } else {
      console.log("User is not authenticated, redirecting to home page");
      navigate("/");
    }
  }, [isAuthLoading, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (apolloClient) {
      console.log("Apollo client is set, getting user");
      apolloClient
        .query({
          query: GET_USER,
        })
        .then((res) => {
          const user = res.data?.user;
          if (user) {
            setUser(user);
            setSelectedClientId(selectedClientId || user?.clients[0]?.id);
          } else {
            // User doesn't exist, create them
            apolloClient
              .mutate({
                mutation: CREATE_USER,
                variables: {
                  name: auth0User.name,
                  email: auth0User.email,
                },
              })
              .then((res) => {
                const createdUser = res.data.createUser;
                setUser(createdUser);
                setSelectedClientId(createdUser?.clients[0]?.id);
                setHasError(false);
                posthog?.capture("user_signed_up");
                navigate("/app");
              })
              .catch((err) => {
                console.log("err", err);
                setHasError(true);
              });
          }
        })
        .catch((err) => {
          console.log("err", err);
          setHasError(true);
        });
    }
  }, [apolloClient]);

  useEffect(() => {
    if (user) {
      posthog?.identify(user.id, {
        name: user.name,
        email: user.email,
      });

      // don't send group call if user is not in selected client (true for god mode login)
      if (includes(user.clients?.map((client) => client.id), selectedClient?.id)) {
        posthog?.resetGroups();
        posthog?.group("company", selectedClient.id, {
          // add name as property if it's not a default name
          name: selectedClient?.name !== "Default" ? selectedClient?.name : undefined,
        });
      }
    }
  }, [user, selectedClient]);

  const isLoadingAppContext = isAuthLoading || !apolloClient || !user || !selectedClient;

  if (hasError) {
    return (
      <ErrorPage
        errorCode=""
        errorTitle="Error logging in"
        errorDescription="Sorry about this. Contact support and we'll figure this out right away."
      />
    );
  }

  return (
    <AppContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoadingAppContext,
        clients: allClients,
        selectedClient,
        selectedClientId,
        setSelectedClientId,
        authToken,
      }}
    >
      {isLoadingAppContext ? (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <LoadingSpinner />
        </div>
      ) : (
        <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
      )}
    </AppContext.Provider>
  );
};
