import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { clsx } from "clsx";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { HashLink } from "react-router-hash-link";

import { Button } from "../components/Button";
import { Container } from "../components/Container";
{
  /* import { Logo } from '../components/Logo' */
}
import { NavLink } from "../components/NavLink";
import { TuskLogo } from "../components/TuskLogo";
import { ProductHuntSocialProofWeek } from "./ProductHuntSocialProofWeek";

function MobileNavLink({
  href,
  onClick,
  children,
  target,
  rel,
}: {
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
  target?: string;
  rel?: string;
}) {
  return (
    <HashLink
      onClick={onClick}
      to={href}
      smooth
      className="block w-full p-2"
      target={target}
      rel={rel}
    >
      <Popover.Button>{children}</Popover.Button>
    </HashLink>
  );
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx("origin-center transition", open && "scale-90 opacity-0")}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx("origin-center transition", !open && "scale-90 opacity-0")}
      />
    </svg>
  );
}

function MobileNavigation() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <MobileNavLink href="/#features">Features</MobileNavLink>
            <MobileNavLink href="/#customers">Customers</MobileNavLink>
            <MobileNavLink
              href="https://docs.usetusk.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Documentation
            </MobileNavLink>
            <MobileNavLink href="/#pricing">Pricing</MobileNavLink>
            <MobileNavLink href="/#faq">FAQ</MobileNavLink>
            <MobileNavLink href="/privacy">Privacy</MobileNavLink>
            <hr className="m-2 border-slate-300/40" />
            <MobileNavLink onClick={() => loginWithRedirect()}>Login</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

export function Header() {
  const { loginWithRedirect } = useAuth0();

  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <div className="h-8 w-auto" style={{ width: "100px" }}>
              <Link to="/#" aria-label="Home">
                <TuskLogo />
                {/* <Logo className="h-10 w-auto" /> */}
              </Link>
            </div>
            <div className="hidden md:flex md:gap-x-6">
              <NavLink href="/#features">Features</NavLink>
              <NavLink href="/#customers">Customers</NavLink>
              <NavLink href="https://docs.usetusk.ai/" target="_blank" rel="noopener noreferrer">
                Docs
              </NavLink>
              <NavLink href="/#pricing">Pricing</NavLink>
              {/* <NavLink href="/#faq">FAQ</NavLink> */}
              <NavLink href="/privacy">Security</NavLink>
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <a className="hidden md:block">
              <ProductHuntSocialProofWeek />
            </a>
            <div className="hidden md:flex md:gap-x-6">
              <NavLink onClick={() => loginWithRedirect()}>Login</NavLink>
            </div>
            <a onClick={() => loginWithRedirect()}>
              <Button variant="outline" color="slate">
                <span>Try for free</span>
              </Button>
            </a>
            <div className="-mr-1 md:hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
}
