import { Button } from "../components/Button";
import { Container } from "../components/Container";
import backgroundImage from "../images/background-light-purple.png";

export function CallToAction() {
  return (
    <section id="get-started-today" className="relative overflow-hidden bg-blue-600 py-32">
      <img
        className="absolute left-1/2 top-1/2 max-w-none w-full h-full -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        width={2347}
        height={1244}
      />
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Never do a chore again.
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Since you made it all the way down here, book a personalized demo with our founders 🎉
          </p>
          <Button href="https://cal.com/marceltan/demo" color="white" className="mt-10">
            Book demo
          </Button>
        </div>
      </Container>
    </section>
  );
}
