import { find, isEmpty } from "lodash";
import { useQuery } from "@apollo/client";

import {
  CopyText,
  LoadingSpinner,
  IconTooltip,
  ScheduleOnboardingModal,
  NonAuthExternalResource,
  ErrorPage,
} from "../components";
import {
  JiraAuth,
  LinearAuth,
  GithubAuth,
  NotionAuth,
  FigmaAuth,
  GithubIssuesIntegration,
} from "./integrations-auth";

import { gql } from "../__generatedGQL__/gql";
import { ResourceType } from "../__generatedGQL__/graphql";
import { useEffect, useMemo, useState } from "react";
import { useAppContext } from "../providers";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useExternalTicketingHook } from "../hooks/useExternalTicketingHook";

export const GET_RESOURCES = gql(`
  query GetResources {
    resources {
      id
      externalId
      type
      userId
      createdAt
      updatedAt
      config
    }
  }
`);

export const InformationBanner = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="mt-2 rounded-md bg-purple-50 p-4">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-purple-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm text-gray-6000">{children}</h3>
        </div>
      </div>
    </div>
  );
};

export const IntegrationSettings = () => {
  const { selectedClientId, user } = useAppContext();
  const { loading, error, data, refetch } = useQuery(GET_RESOURCES);
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

  const githubResource = find(data?.resources, { type: ResourceType.Github });
  const linearResource = find(data?.resources, { type: ResourceType.Linear });
  const jiraResource = find(data?.resources, { type: ResourceType.Jira });
  const notionResource = find(data?.resources, { type: ResourceType.Notion });
  const figmaResource = find(data?.resources, { type: ResourceType.Figma });

  useEffect(() => {
    /**
     * User by default is dropped in DashboardPage. We get redirected to IntegrationSettings from DashboardPage and show onboarding modal if both are true:
     * - User doesn't have GitHub connected
     * - Onboarding modal hasn't been shown yet for this session
     */

    const sessionStorageKey = `${selectedClientId}.${user?.id}.shownOnboardingModal`;
    const shownOnboardingModal = sessionStorage.getItem(sessionStorageKey);
    if (!loading && !githubResource && !shownOnboardingModal) {
      sessionStorage.setItem(`${selectedClientId}.${user?.id}.shownOnboardingModal`, "true");
      setOnboardingModalOpen(true);
    }
  }, [loading, githubResource]);

  const { isExternalTicketingConnected } = useExternalTicketingHook();

  const integrationsSubheader = useMemo(() => {
    const isGithubConnected = !!githubResource;
    const isNotionConnected = !!notionResource;

    if (!isGithubConnected) {
      return (
        <InformationBanner>
          Connect Tusk to your <span className="font-semibold">GitHub</span> to start creating
          issues. Learn more about{" "}
          <a
            href="https://docs.usetusk.ai/onboarding"
            className="text-purple-600 hover:text-purple-800"
            target="_blank"
            rel="noopener noreferrer"
          >
            syncing repos
          </a>{" "}
          and our{" "}
          <a
            href="https://docs.usetusk.ai/security-practices"
            className="text-purple-600 hover:text-purple-800"
            target="_blank"
            rel="noopener noreferrer"
          >
            security practices
          </a>
          .
        </InformationBanner>
      );
    } else if (!isExternalTicketingConnected) {
      return (
        <InformationBanner>
          Connect Tusk to your <span className="font-semibold">project management app</span> to
          surface issues and assign them to Tusk. For help, read{" "}
          <a
            href="https://docs.usetusk.ai/ticketing-integrations"
            className="text-purple-600 hover:text-purple-800"
            target="_blank"
            rel="noopener noreferrer"
          >
            this doc
          </a>{" "}
          or reach out to <CopyText text="support@usetusk.ai" type="email" />.
        </InformationBanner>
      );
    } else if (isNotionConnected && isEmpty(notionResource.config?.databases)) {
      return (
        <InformationBanner>
          Please contact <CopyText text="support@usetusk.ai" type="email" /> to finish setting up
          the <span className="font-semibold">Notion integration</span>.
        </InformationBanner>
      );
    } else {
      return (
        <p className="mt-2 text-sm leading-6 text-gray-600">
          Connect Tusk to your workplace apps. For help, read{" "}
          <a
            href="https://docs.usetusk.ai/ticketing-integrations"
            className="text-purple-600 hover:text-purple-800"
            target="_blank"
            rel="noopener noreferrer"
          >
            this doc
          </a>{" "}
          or reach out to <CopyText text="support@usetusk.ai" type="email" />.
        </p>
      );
    }
  }, [githubResource, linearResource, jiraResource, notionResource, isExternalTicketingConnected]);

  if (loading) return <LoadingSpinner />;
  if (error) {
    return (
      <ErrorPage
        errorCode="500"
        errorTitle="Error loading integrations"
        errorDescription={error.message}
      />
    );
  }

  return (
    <>
      <div className="space-y-8 sm:space-y-8">
        <div>
          <h2 className="text-lg font-semibold leading-7 text-gray-900">Integrations</h2>
          {integrationsSubheader}
          <div className="mt-4 border-t border-gray-100">
            <div className="divide-y divide-gray-100" />
            <div className="mt-4 text-md font-semibold leading-6 text-gray-900">
              Code hosting platform
            </div>
            <div className="flex flex-wrap justify-start gap-8 mt-4">
              <GithubAuth resource={githubResource} />
            </div>
          </div>
          <div className="mt-4 border-t border-gray-100">
            <div className="divide-y divide-gray-100" />
            <div className="flex items-center mt-4 text-md font-semibold leading-6 text-gray-900">
              Project management platform
              <IconTooltip tooltipText="Once connected, you can add the 'Tusk' label to a ticket to generate a pull request. Our AI will also auto-surface tickets that it can complete." />
            </div>
            <div className="flex flex-wrap justify-start gap-4 mt-4">
              <LinearAuth resource={linearResource} />
              <NotionAuth resource={notionResource} />
              <JiraAuth resource={jiraResource} />
              <GithubIssuesIntegration
                resource={githubResource}
                refetchResources={async () => {
                  await refetch();
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 border-t border-gray-100">
          <div className="divide-y divide-gray-100" />
          <div className="flex items-center mt-4 text-md font-semibold leading-6 text-gray-900">
            External link integrations
            <IconTooltip tooltipText="Connect other integrations that are referred to in your tickets to provide more context." />
          </div>
          <div className="flex flex-wrap justify-start gap-4 mt-4">
            <FigmaAuth resource={figmaResource} />
            <NonAuthExternalResource
              resourceName="Jam"
              resourceLogo="https://storage.googleapis.com/use-tusk-dev-static/jam-logo.jpeg"
              resourceDescription="Extract information from Jam links"
              tooltipText="Tusk automatically extracts information from public Jam links"
            />
            <NonAuthExternalResource
              resourceName="Bird Eats Bug"
              resourceLogo="https://storage.googleapis.com/use-tusk-dev-static/birdeatsbug-logo.svg"
              resourceDescription="Extract information from BEB links"
              tooltipText="Tusk automatically extracts information from public Bird Eats Bug links"
            />
            <NonAuthExternalResource
              resourceName="Loom"
              resourceLogo="https://asset.brandfetch.io/iddLCZGlbs/id3sYlMdzs.png"
              resourceDescription="Extract information from Loom videos"
              tooltipText="Tusk automatically extracts information from public Loom videos"
            />
            <NonAuthExternalResource
              resourceName="GitHub"
              resourceLogo="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"
              resourceDescription="Extract information from GitHub links"
              tooltipText="Tusk automatically extracts information from public and private (if authed) GitHub links"
            />
          </div>
        </div>
      </div>
      <ScheduleOnboardingModal open={onboardingModalOpen} setOpen={setOnboardingModalOpen} />
    </>
  );
};
