import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AppPage } from "../pages/AppPage";
import { LoadingSpinner } from "./LoadingSpinner";

export const AuthenticatedRoute = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    // Show a large loading spinner while authentication status is being determined
    return <LoadingSpinner size="lg" />;
  }

  if (!isAuthenticated) {
    // Perform no action since loginWithRedirect will handle navigation
    return null;
  }

  // Direct authenticated users to the AppPage component
  return <AppPage />;
};
