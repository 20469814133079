import { useState, ReactElement } from "react";
import { Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { clsx } from "clsx";

interface IconTooltipProps {
  icon?: ReactElement;
  tooltipText?: string;
  iconClassName?: string;
  tooltipClassName?: string;
}

export const IconTooltip = ({
  icon,
  tooltipText,
  iconClassName,
  tooltipClassName,
}: IconTooltipProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const IconComponent = icon ? () => icon : QuestionMarkCircleIcon;

  return (
    <span className="relative inline-block ml-1">
      <div
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        // className="cursor-pointer"
      >
        <IconComponent
          className={clsx("h-5 w-5 text-gray-500", iconClassName)}
          aria-hidden="true"
        />
      </div>
      <Transition
        show={Boolean(tooltipText && isTooltipVisible)}
        enter="transition-opacity ease-linear duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={clsx(
            "absolute z-10 w-80 top-1/2 left-full transform -translate-y-1/2 ml-2 bg-gray-900 text-white text-xs font-normal rounded py-1 px-2",
            tooltipClassName,
          )}
        >
          {tooltipText}
        </div>
      </Transition>
    </span>
  );
};
