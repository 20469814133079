import { useMemo, useRef, useEffect } from 'react';
export function usePrevious(value) {
  var ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export function useHighlight(_ref) {
  var {
    value,
    highlightUpdates,
    highlightContainer
  } = _ref;
  var prevValue = usePrevious(value);
  var isHighlight = useMemo(() => {
    if (!highlightUpdates || prevValue === undefined) return false;
    // highlight if value type changed
    if (typeof value !== typeof prevValue) {
      return true;
    }
    if (typeof value === 'number') {
      // notice: NaN !== NaN
      if (isNaN(value) && isNaN(prevValue)) return false;
      return value !== prevValue;
    }
    // highlight if isArray changed
    if (Array.isArray(value) !== Array.isArray(prevValue)) {
      return true;
    }
    // not highlight object/function
    // deep compare they will be slow
    if (typeof value === 'object' || typeof value === 'function') {
      return false;
    }

    // highlight if not equal
    if (value !== prevValue) {
      return true;
    }
  }, [highlightUpdates, value]);
  useEffect(() => {
    if (highlightContainer && highlightContainer.current && isHighlight && 'animate' in highlightContainer.current) {
      highlightContainer.current.animate([{
        backgroundColor: 'var(--w-rjv-update-color, #ebcb8b)'
      }, {
        backgroundColor: ''
      }], {
        duration: 1000,
        easing: 'ease-in'
      });
    }
  }, [isHighlight, value, highlightContainer]);
}