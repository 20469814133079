import { BoltIcon, DocumentTextIcon, SparklesIcon } from "@heroicons/react/20/solid";
import { Button } from "./Button";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../providers";
import { includes } from "lodash";
import { ClientFeatures } from "../__generatedGQL__/graphql";
import { CopyText } from "./CopyText";

interface IProps {
  isExternalTicketingConnected: boolean;
}

export const AutoTriageEmptyState = ({ isExternalTicketingConnected }: IProps) => {
  const { selectedClientId, selectedClient } = useAppContext();
  const navigate = useNavigate();

  return (
    <div className="text-center py-16 rounded-lg border border-gray-300">
      <SparklesIcon className="mx-auto h-12 w-12 text-purple-500" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No suggested issues yet</h3>
      {isExternalTicketingConnected ? (
        <>
          {includes(selectedClient.features, ClientFeatures.AutoTriage) ? (
            <div className="max-w-[380px] mx-auto">
              <p className="mt-2 text-sm text-gray-500">
                Please allow a few minutes after connecting your project management app for your
                first suggestions to appear.
              </p>
            </div>
          ) : (
            <div className="max-w-[280px] mx-auto">
              <p className="mt-2 text-sm text-gray-500">
                Please contact <CopyText text="support@usetusk.ai" type="email" /> to enable
                suggested issues.
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="max-w-[340px] mx-auto">
          <p className="mt-2 text-sm text-gray-500">
            Connect your project management app to see issues that Tusk can work on immediately.
          </p>
          <div className="mt-6">
            <Button
              size="lg"
              type="button"
              onClick={() => navigate(`/app/settings?client=${selectedClientId}`)}
            >
              Connect now
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
