import { Container } from "../components/Container";
import backgroundImage from "../images/background-faqs.jpg";

const faqs = [
  [
    {
      question: "How long does it take to set up Tusk?",
      answer:
        "It takes 5 mins to start generating PRs. At minimum, you need to connect GitHub and sync your repos. Connect Jira, Linear, or Notion for the full productivity boost.",
    },
    {
      question: "Can Tusk integrate with my frontend stack?",
      answer:
        "Tusk is versatile and designed to adapt to your unique codebase. It works seamlessly with a wide array of frontend frameworks including Next.js, React, Angular, and Vue.js, as well as design libraries like Material UI, Ant Design, and Chakra UI.",
    },
    // {
    //   question: 'How do I apply for a job at TaxPal?',
    //   answer:
    //     'We only hire our customers, so subscribe for a minimum of 6 months and then let’s talk.',
    // },
  ],
  [
    {
      question: "Where does Tusk perform best?",
      answer:
        "Tusk performs best at smaller tickets like copy changes, UI/UX improvements, and simple bug fixes for now. We ship fast and are actively improving its capabilities.",
    },
    {
      question: "How does Tusk handle code storage and data security?",
      answer:
        "Tusk fetches your code from GitHub at runtime without permanent storage on our servers. Your data privacy is extremely important to us. Please see our Privacy Policy for more information.",
    },
    // {
    //   question:
    //     'I found other companies called TaxPal, are you sure you can use this name?',
    //   answer:
    //     'Honestly not sure at all. We haven’t actually incorporated or anything, we just thought it sounded cool and made this website.',
    // },
  ],
  [
    {
      question: "Will this replace me?",
      answer:
        "No. Tusk is your AI assistant. We are on a mission to boost developer productivity and satisfaction by automating the day-to-day tasks that bog you down.",
    },
    {
      question: "How can I trust that Tusk generates code that works?",
      answer:
        "Tusk regenerates code to address PR reviews and errors in automated checks. Tusk also autonomously tests its changes on your preview environment and records a testing video to make QA easy.",
    },
    // {
    //   question: "I lost my password, how do I get into my account?",
    //   answer:
    //     "Send us an email and we will send you a copy of our latest password spreadsheet so you can find your information.",
    // },
    ,
  ],
];

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Didn't find an answer to your question? Email us at{" "}
            <a href="mailto:founders@usetusk.ai" className="text-purple-600 hover:text-purple-800">
              founders@usetusk.ai
            </a>
            .
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
