import { isString } from "lodash";
import Markdown, { MarkdownToJSX } from "markdown-to-jsx";

interface IProps extends MarkdownToJSX.Options {
  children: string;
  onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const CustomMarkdown = ({ children, onLinkClick }: IProps) => {
  if (!children || !isString(children)) {
    console.error("CustomMarkdown: children is not a string", children);
    return null;
  }

  return (
    <Markdown
      options={{
        overrides: {
          a: ({ node, ...props }) => (
            <a
              {...props}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(event) => {
                if (onLinkClick) {
                  onLinkClick(event);
                }
              }}
            />
          ),
        },
      }}
    >
      {children}
    </Markdown>
  );
};
