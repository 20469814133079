import { useState, useEffect, useMemo, createElement } from "react";
import { get, includes, filter } from "lodash";
import { PencilIcon, NewspaperIcon } from "@heroicons/react/20/solid";
import { clsx } from "clsx";
import { formatDistanceToNow } from "date-fns";

import { AnimatedEllipsis } from "./AnimatedEllipsis";
import { IAgentLog } from "./TaskAgentLog";
import { CustomMarkdown } from "./Markdown";

interface IProps {
  isRunning: boolean;
  agentLogs: IAgentLog[];
  toolMap: {
    [toolName: string]: {
      humanReadableName: string;
      icon: any; // TODO: use proper type
    };
  };
  filterAgentLogs?: (agentLogs: IAgentLog[]) => IAgentLog[];
}

export const AgentLogsList = ({ agentLogs, toolMap, isRunning, filterAgentLogs }: IProps) => {
  const [visibleAgentLogDescriptions, setVisibleAgentLogDescriptions] = useState<number[]>([]);

  const updatedAgentLogs = useMemo(() => {
    const filteredAgentLogs = filterAgentLogs ? filterAgentLogs(agentLogs) : agentLogs;
    if (isRunning) {
      return [
        ...filteredAgentLogs,
        {
          timestamp: Date.now(),
          type: "tool",
          toolName: "thinking",
          thought: undefined,
          observation: undefined,
          nestedLogs: undefined,
        },
      ];
    }
    return filteredAgentLogs;
  }, [agentLogs, isRunning, filterAgentLogs]);

  return (
    <ul role="list" className="mt-6 -mb-8">
      {updatedAgentLogs.map((agentLog, agentLogIdx) => {
        let Icon: any; // TODO: use proper type
        let title: string;
        let description: string;

        if (agentLog.type === "tool") {
          Icon = get(toolMap, agentLog.toolName)?.icon || PencilIcon;
          title = get(toolMap, agentLog.toolName)?.humanReadableName;
          description = agentLog.thought;
        } else if (agentLog.type === "observation") {
          Icon = NewspaperIcon;
          title = "Observation";
          description = agentLog.observation;
        }

        if (!title) {
          return null;
        }

        return (
          <li key={agentLog.timestamp}>
            <div className="relative pb-6">
              {agentLogIdx !== updatedAgentLogs.length - 1 ? (
                <span
                  className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex items-start space-x-3">
                <>
                  <div>
                    <div className="relative px-1">
                      <div
                        className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white cursor-pointer hover:bg-gray-200"
                        onClick={() =>
                          description &&
                          setVisibleAgentLogDescriptions(
                            includes(visibleAgentLogDescriptions, agentLog.timestamp)
                              ? filter(
                                  visibleAgentLogDescriptions,
                                  (ts) => ts !== agentLog.timestamp,
                                )
                              : [...visibleAgentLogDescriptions, agentLog.timestamp],
                          )
                        }
                      >
                        {createElement(Icon, {
                          className: "h-5 w-5 text-gray-500",
                          "aria-hidden": true,
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="min-w-0 flex-1 py-1.5">
                    <div
                      className={clsx(
                        "text-sm text-gray-500",
                        includes(visibleAgentLogDescriptions, agentLog.timestamp)
                          ? "font-semibold"
                          : "font-normal",
                        description ? "cursor-pointer" : "",
                      )}
                      onClick={() =>
                        description &&
                        setVisibleAgentLogDescriptions(
                          includes(visibleAgentLogDescriptions, agentLog.timestamp)
                            ? filter(visibleAgentLogDescriptions, (ts) => ts !== agentLog.timestamp)
                            : [...visibleAgentLogDescriptions, agentLog.timestamp],
                        )
                      }
                    >
                      <span>{title}</span>
                      {agentLog.toolName !== "thinking" && (
                        <span
                          className="ml-2 text-xs text-gray-400"
                          title={new Date(agentLog.timestamp).toLocaleString()}
                        >
                          {formatDistanceToNow(new Date(agentLog.timestamp), { addSuffix: true })}
                        </span>
                      )}
                      {agentLog.toolName === "thinking" && <AnimatedEllipsis />}
                    </div>
                    {includes(visibleAgentLogDescriptions, agentLog.timestamp) && (
                      <>
                        {description && (
                          <div className="mt-2 prose max-w-none prose-headings:text-sm text-sm">
                            <CustomMarkdown>{description}</CustomMarkdown>
                          </div>
                        )}
                        {agentLog.nestedLogs && (
                          <AgentLogsList
                            filterAgentLogs={filterAgentLogs}
                            agentLogs={agentLog.nestedLogs}
                            toolMap={toolMap}
                            isRunning={false}
                          />
                        )}
                      </>
                    )}
                  </div>
                </>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};
