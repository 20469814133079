import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

export const VideoModal = ({
  modalOpen,
  setModalOpen,
  videoUrl,
  videoWidth = 1920,
  videoHeight = 1080,
}: {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  videoUrl: string;
  videoWidth?: number;
  videoHeight?: number;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <div>
      <Transition
        show={modalOpen}
        as={Fragment}
        afterEnter={() => {
          if (videoRef.current) {
            videoRef.current.muted = true;
            videoRef.current.play();
          }
        }}
      >
        <Dialog initialFocus={videoRef} onClose={() => setModalOpen(false)}>
          {/* Modal backdrop */}
          <Transition.Child
            className="fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity"
            enter="transition ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            aria-hidden="true"
          />
          {/* End: Modal backdrop */}

          {/* Modal dialog */}
          <Transition.Child
            className="fixed inset-0 z-50 flex p-6"
            enter="transition ease-out duration-300"
            enterFrom="opacity-0 scale-75"
            enterTo="opacity-100 scale-100"
            leave="transition ease-out duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
          >
            <div className="max-w-5xl mx-auto h-full flex items-center z-50">
              <Dialog.Panel className="w-full max-h-full shadow-2xl aspect-video bg-black">
                <video
                  className="outline-none"
                  ref={videoRef}
                  width={videoWidth}
                  height={videoHeight}
                  controls
                >
                  <source src={videoUrl} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
              </Dialog.Panel>
            </div>
          </Transition.Child>
          {/* End: Modal dialog */}
        </Dialog>
      </Transition>
    </div>
  );
};
