import { useQuery } from "@apollo/client";
import { compact, find } from "lodash";
import { gql } from "../__generatedGQL__/gql";
import { ResourceType } from "../__generatedGQL__/graphql";

export const GET_RESOURCES = gql(`
  query GetResources {
    resources {
      id
      externalId
      type
      userId
      createdAt
      updatedAt
      config
    }
  }
`);

export const useExternalTicketingHook = () => {
  const { loading, error, data } = useQuery(GET_RESOURCES);
  console.log("data", data);

  if (loading || error) {
    return { loading, error, isExternalTicketingConnected: false, connectedTicketingResources: [] };
  }

  const githubResource = find(data?.resources, { type: ResourceType.Github });
  const linearResource = find(data?.resources, { type: ResourceType.Linear });
  const jiraResource = find(data?.resources, { type: ResourceType.Jira });
  const notionResource = find(data?.resources, { type: ResourceType.Notion });

  const isGithubConnected = !!githubResource;
  const isExternalTicketingConnected =
    !!linearResource ||
    !!jiraResource ||
    !!notionResource ||
    (isGithubConnected && githubResource.config?.issuesIntegrationEnabled);

  return {
    loading,
    error,
    isExternalTicketingConnected: Boolean(isExternalTicketingConnected),
    connectedTicketingResources: compact([
      linearResource,
      jiraResource,
      notionResource,
      githubResource,
    ]),
  };
};
