import { ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { Helmet } from "react-helmet";

const stats = [
  { label: "PRs merged", value: "73" },
  { label: "Cost savings", value: "$5.2K" },
  { label: "ROI", value: "365.4%" },
];

export const CaseStudyArticle = () => {
  return (
    <>
      <Helmet>
        <title>Tusk | How HitPay Saw 365% ROI in Three Months With Tusk</title>
        <meta
          name="description"
          content="HitPay saw 365% ROI and merged 73 AI-created pull requests in 3 months using Tusk's AI coding agent."
        />
      </Helmet>
      <div className="bg-white px-6 pt-12 pb-24 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-purple-600">Customer Story</p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            How HitPay Saw 365% ROI in Three Months With Tusk
          </h1>
          <div className="mt-4 flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-600">
            <time className="mr-8">May 29, 2024</time>
            <div className="-ml-4 flex items-center gap-x-4">
              <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-gray-900/50">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <div className="flex gap-x-2.5">
                <img
                  src="https://storage.googleapis.com/use-tusk-dev-static/marcel_tan_photo.jpg"
                  alt=""
                  className="h-6 w-6 flex-none rounded-full bg-white/10"
                />
                Marcel Tan
              </div>
            </div>
          </div>
          <figure className="mt-8">
            <img
              className="aspect-video rounded-xl bg-gray-50 object-cover"
              src="https://storage.googleapis.com/use-tusk-dev-static/hitpay_product.png"
              alt="Graphic showing the UI of HitPay's web app"
            />
            <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
              HitPay's platform powers easy, affordable digital payments for small and medium
              enterprises (SMEs)
            </figcaption>
          </figure>
          <h2 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Introduction</h2>
          {/* <p className="mt-6 text-xl leading-8">
      Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi,
      nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in
      viverra scelerisque eget. Eleifend egestas fringilla sapien.
    </p> */}
          <div className="mt-4 max-w-4xl">
            <p>
              <strong className="font-semibold text-gray-900">
                HitPay is the all-in-one payment platform for SMEs.
              </strong>{" "}
              Backed by Y Combinator, Tiger Global, Global Founders Capital, and HOF Capital, HitPay
              helps over 15,000 businesses across Southeast Asia and around the globe process
              payments efficiently and securely. They unify online, point of sale, and B2B payments
              into a single, integrated payment processing system.
            </p>
            <h2 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">Why Tusk?</h2>
            <p className="mt-4">
              HitPay encountered growing pains as they scaled quickly across Southeast Asia after
              raising their Series A round. Being a B2B2C product, their team was seeing minor bugs
              and UI-related nits crop up in their sprints and backlog. They needed to keep their
              product quality high, but these "quality of life" tasks were weighing down their lean
              engineering team.
            </p>
            <p className="mt-4">
              <strong className="font-semibold text-gray-900">
                Nitin Muthyala, Co-Founder & CTO of HitPay
              </strong>
              , was looking for an AI coding solution that could reduce the burden of minor app
              changes on his engineers. At the same time, he wanted a tool that was able to fit
              seamlessly into his team’s workflow.
            </p>
            <p className="mt-4">
              He had previously tried other AI coding solutions but ran into two key limitations:
            </p>
            <ul role="list" className="mt-6 max-w-4xl space-y-4 text-gray-600">
              <li className="flex gap-x-3">
                <ExclamationCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-purple-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">High friction.</strong> These
                  tools required engineers and product managers (PMs) to adopt new workflows, adding
                  friction to the end user and complicating the roll-out for leadership.
                </span>
              </li>
              <li className="flex gap-x-3">
                <ExclamationCircleIcon
                  className="mt-1 h-5 w-5 flex-none text-purple-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">Learning curve.</strong> These
                  tools had a steep learning curve. They required developers to specify file names
                  and functions for the AI, which defeated the purpose of saving developer time.
                </span>
              </li>
            </ul>
            <figure className="mt-8">
              <img
                className="aspect-video rounded-xl bg-gray-50 object-cover"
                src="https://storage.googleapis.com/use-tusk-dev-static/hitpay_tusk_example_case_edited.png"
                alt="Graphic showing how HitPay uses Tusk to automate a grammar fix on a modal"
              />
              <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
                {/* <InformationCircleIcon
    className="mt-0.5 h-5 w-5 flex-none text-gray-300"
    aria-hidden="true"
  /> */}
                HitPay's workflow for using Tusk to automate UI improvements to their product
              </figcaption>
            </figure>
            <p className="mt-8">
              <strong className="font-semibold text-gray-900">
                Nitin found the ideal solution in Tusk.
              </strong>{" "}
              Tusk’s code generation capability was able to complete common chore tickets in
              HitPay's sprint like minor bugs and UI polish issues. This relieved the burden of
              chores on HitPay’s engineers, boosting developer productivity and satisfaction.
            </p>
            <p className="mt-4">
              Tusk also empowered HitPay’s PMs to push changes to the web app without distracting
              their engineers. Engineering would simply have to review and merge the Tusk-created
              pull request (PR) to get the change out.
            </p>
            <h2 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">
              Tusk Implementation
            </h2>
            <figure className="mt-10 border-l border-purple-600 pl-9">
              <blockquote className="font-semibold text-gray-900">
                <p>
                  "I love Tusk's integration with Linear. That has been a key ask for us. When we
                  explored other AI developer tools, we found that they had a learning curve and
                  forced us to adapt to a new workflow, which wasn’t ideal."
                </p>
              </blockquote>
              <figcaption className="mt-6 flex gap-x-3">
                <img
                  className="h-6 w-6 flex-none rounded-full bg-gray-50"
                  src="https://storage.googleapis.com/use-tusk-dev-static/nitin_muthyala_photo.jpeg"
                  alt=""
                />
                <div className="text-sm leading-6">
                  <strong className="font-semibold text-gray-900">Nitin Muthyala</strong> –
                  Co-Founder & CTO of HitPay
                </div>
              </figcaption>
            </figure>
            <p className="mt-10">
              Nitin spearheaded the initiative by connecting Tusk to GitHub and Linear and running
              the AI agent on copy changes that he had in mind. Once confident in the product, he
              brought in a PM and designer to unearth use cases based on existing tickets in their
              sprint and backlog.
            </p>
            <p className="mt-4">
              <strong className="font-semibold text-gray-900">
                Engineering set-up for Tusk was painless and took less than 1 working day.
              </strong>{" "}
              HitPay’s engineering manager and senior engineers then had a quick conversation with
              the Tusk team to customize the AI agent to their codebase and integrate with their
              CI/CD.
            </p>
            <p className="mt-4">
              One challenge that the HitPay team encountered was determining which tickets Tusk
              could complete end to end. The Tusk team worked hand in hand with HitPay to build
              <strong className="font-semibold text-gray-900"> Auto-Triaging</strong>, a feature
              which enabled Tusk to automatically flag tickets that it could complete.
            </p>
            <p className="mt-4">
              Noticing that HitPay was using Bird Eats Bug to report bugs, the Tusk team also built
              integrations with Bird Eats Bug and Jam, which allowed the agent to extract screen
              recordings for additional task context.
            </p>
          </div>
          <div className="max-w-4xl">
            <h2 className="mt-8 text-2xl font-semibold tracking-tight text-gray-900">
              Results in Three Months
            </h2>
            <dl className="mt-8 grid grid-cols-2 gap-8 sm:grid-cols-4">
              {stats.map((stat, statIdx) => (
                <div key={statIdx}>
                  <dt className="text-sm font-semibold leading-6 text-gray-600">{stat.label}</dt>
                  <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
            <p className="mt-8">
              The HitPay engineering team was impressed by the quality and reliability of Tusk’s
              code generation. Tusk could create working PRs for tickets written in plain English,
              at times even catching things that human engineers would have missed (e.g., copy
              changes that require a change in business logic).
            </p>
            <figure className="mt-10 border-l border-purple-600 pl-9">
              <blockquote className="font-semibold text-gray-900">
                <p>
                  “Tusk takes weight away from our developers. It’s truly been a copilot for our
                  developers as well as product managers.”
                </p>
              </blockquote>
              <figcaption className="mt-6 flex gap-x-3">
                <img
                  className="h-6 w-6 flex-none rounded-full bg-gray-50"
                  src="https://storage.googleapis.com/use-tusk-dev-static/nitin_muthyala_photo.jpeg"
                  alt=""
                />
                <div className="text-sm leading-6">
                  <strong className="font-semibold text-gray-900">Nitin Muthyala</strong> –
                  Co-Founder & CTO of HitPay
                </div>
              </figcaption>
            </figure>
            <p className="mt-10">
              HitPay now uses Tusk day to day to free up bandwidth for engineers to focus on more
              important work, while enabling PMs to push product improvements on their own. Because
              all it takes to use Tusk is adding a label to their Linear tickets, adoption amongst
              engineers and PMs remains high.
            </p>
            <p className="mt-10 border-t border-gray-900/10 pt-10">
              If you’d like to automate away frontend chores to boost developer productivity and
              satisfaction,{" "}
              <a
                href="https://cal.com/marceltan/demo"
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                book a Tusk demo with the founders.
              </a>
            </p>
            <p className="mt-4">
              If you’re an SME looking for an all-in-one platform to handle online, point of sale,
              and B2B payments,{" "}
              <a
                href="https://www.hitpayapp.com/"
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                register for the HitPay platform.
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
