import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  Cog6ToothIcon,
  ArrowLeftOnRectangleIcon,
  BookOpenIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import { size, map, includes, some, find } from "lodash";
import { usePostHog } from "posthog-js/react";

import { TuskLogo } from "../marketing/components/TuskLogo";
import { useAppContext } from "../providers";
import { AvatarButtonGroup } from "./AvatarButtonGroup";

export const getInitials = (name: string): string => {
  return name
    .split(" ")
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
};

export const getInitialsColor = (name: string): string => {
  const initials = getInitials(name);
  const colors = [
    "bg-red-500",
    "bg-orange-500",
    "bg-yellow-500",
    "bg-lime-500",
    "bg-emerald-500",
    "bg-cyan-500",
    "bg-blue-500",
    "bg-violet-500",
    "bg-fuchsia-500",
    "bg-pink-500",
  ];
  const color = colors[(initials.charCodeAt(0) || 0 + initials.charCodeAt(1) || 0) % colors.length];
  return color;
};

const navigation = [
  { name: "Dashboard", path: "/app", additionalPaths: [], icon: HomeIcon },
  {
    name: "Suggested Issues",
    path: "/app/suggested-issues",
    additionalPaths: [],
    icon: SparklesIcon,
  },
  // { name: 'Team', path: '#', icon: UsersIcon },
  // { name: 'Projects', path: '#', icon: FolderIcon },
  // { name: 'Calendar', path: '#', icon: CalendarIcon },
  // { name: 'Documents', path: '#', icon: DocumentDuplicateIcon },
  // { name: 'Reports', path: '#', icon: ChartPieIcon },
  {
    name: "Settings",
    path: "/app/settings",
    additionalPaths: [
      "/app/settings/github",
      "/app/settings/subscription",
      "/app/settings/integrations",
      "/app/settings/team",
    ],
    icon: Cog6ToothIcon,
  },
  {
    name: "Documentation",
    icon: BookOpenIcon,
    path: "https://docs.usetusk.ai/",
    external: true,
  },
];

export const AppSidebar = ({ children }) => {
  const location = useLocation();
  const { logout: logoutAuth0 } = useAuth0();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isAvatarMenuVisible, setIsAvatarMenuVisible] = useState(false);
  const menuRef = useRef(null);
  const initialsButtonRef = useRef(null);

  const handleClose = () => {
    setIsAvatarMenuVisible(false);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      initialsButtonRef.current &&
      !initialsButtonRef.current.contains(event.target) &&
      !event.target.closest(".avatar-button-group")
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { user, clients, selectedClientId, setSelectedClientId } = useAppContext();
  const posthog = usePostHog();

  const logout = () => {
    posthog?.capture("user_logged_out");
    posthog?.reset();
    logoutAuth0({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const buttons = [
    {
      icon: ArrowLeftOnRectangleIcon,
      text: "Log out",
      onClick: logout,
    },
    // Add more buttons as needed
  ];

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex pt-6 h-16 shrink-0 items-center">
                      <div className="h-8 w-auto" style={{ width: "100px" }}>
                        <Link to="/" target="_blank" aria-label="Home">
                          <TuskLogo />
                        </Link>
                      </div>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) =>
                              item.external ? (
                                <a
                                  href={item.path}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800"
                                  }
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {item.name}
                                </a>
                              ) : (
                                <Link
                                  to={item.path}
                                  className={clsx(
                                    some(
                                      map(
                                        [item.path, ...item.additionalPaths],
                                        (path) => path === location.pathname,
                                      ),
                                    )
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {item.name}
                                </Link>
                              ),
                            )}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            Your organizations
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {map(clients, (client) => (
                              <li key={client.id}>
                                <Link
                                  to="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setSelectedClientId(client.id);
                                  }}
                                  className={clsx(
                                    client.id === selectedClientId
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                                  )}
                                >
                                  <span
                                    className={clsx(
                                      client.id !== selectedClientId && "group-hover:text-white",
                                      "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400",
                                    )}
                                  >
                                    {(client.name || "Team")[0]}
                                  </span>
                                  <span className="truncate">
                                    {client.name}
                                    {!includes(map(user.clients, "id"), client.id)
                                      ? " (Admin)"
                                      : ""}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex pt-6 h-16 shrink-0 items-center">
              <div className="h-8 w-auto" style={{ width: "100px" }}>
                <Link to="/" target="_blank" aria-label="Home">
                  <TuskLogo />
                </Link>
              </div>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) =>
                      item.external ? (
                        <a
                          href={item.path}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800"
                          }
                        >
                          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          to={item.path}
                          className={clsx(
                            some(
                              map(
                                [item.path, ...item.additionalPaths],
                                (path) => path === location.pathname,
                              ),
                            )
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                          )}
                        >
                          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                          {item.name}
                        </Link>
                      ),
                    )}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    Your organizations
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {map(clients, (client) => (
                      <li key={client.id}>
                        <Link
                          to="#"
                          onClick={(event) => {
                            event.preventDefault();
                            setSelectedClientId(client.id);
                          }}
                          className={clsx(
                            client.id === selectedClientId
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                          )}
                        >
                          <span
                            className={clsx(
                              client.id !== selectedClientId && "group-hover:text-white",
                              "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400",
                            )}
                          >
                            {(client.name || "Team")[0]}
                          </span>
                          <span className="truncate">
                            {client.name}
                            {!includes(map(user.clients, "id"), client.id) ? " (Admin)" : ""}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <div className="relative" ref={menuRef}>
                    <button
                      ref={initialsButtonRef}
                      onClick={() => setIsAvatarMenuVisible(!isAvatarMenuVisible)}
                      className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800 w-full"
                    >
                      <div
                        className={clsx(
                          "h-8 w-8 rounded-full flex items-center justify-center text-white",
                          getInitialsColor(user.name),
                        )}
                        title={user.name}
                      >
                        {getInitials(user.name)}
                      </div>
                      <span className="sr-only">Your profile</span>
                      <span aria-hidden="true">{user.name}</span>
                    </button>
                    <AvatarButtonGroup isVisible={isAvatarMenuVisible} buttons={buttons} />
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-10 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">
            {find(
              navigation,
              (item) =>
                item.path === location.pathname ||
                includes(item.additionalPaths, location.pathname),
            )?.name || "Dashboard"}
          </div>
          <div className="relative" ref={menuRef}>
            <button
              onClick={() => setIsAvatarMenuVisible(!isAvatarMenuVisible)}
              className="flex items-center gap-x-4 text-sm font-semibold leading-6 text-white"
            >
              <div
                className={clsx(
                  "h-8 w-8 rounded-full flex items-center justify-center text-white",
                  getInitialsColor(user.name),
                )}
                title={user.name}
              >
                {getInitials(user.name)}
              </div>
            </button>
            <AvatarButtonGroup isVisible={isAvatarMenuVisible} buttons={buttons} />
          </div>
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-8 sm:px-10 lg:px-14">{children}</div>
        </main>
      </div>
    </>
  );
};
