import { useEffect, useState, useMemo } from "react";
import { clsx } from "clsx";
import { size, includes, split, join, isNumber, isError } from "lodash";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isApolloError } from "@apollo/client";

import { GetTasksQuery, GetReposQuery, GetTaskStatsQuery } from "../__generatedGQL__/graphql";
import {
  getInitials,
  getInitialsColor,
  IssueEmptyState,
  VideoModal,
  IssueOptions,
  Button,
  IssuesTablePagination,
  PullRequestLimitAlert,
  DeleteTaskModal,
  RetryGenerationModal,
} from "../components";
import {
  TaskSlideOver,
  getMaxSuccessfulTasks,
  getPlanName,
  isMonthlyPlan,
  isPaidPlan,
  InformationBanner,
} from "../containers";
import { useAppContext, useNotificationContext } from "../providers";
import { getGeneratePullRequestRunBranchUrl, getTaskStatus, TaskStatus } from "../utils";

import { getAppUrl } from "../utils";

// New reusable IssueStatus component
interface IssueStatusProps {
  status: TaskStatus;
  className?: string; // Optional className prop
}

export const IssueStatus: React.FC<IssueStatusProps> = ({ status, className }) => {
  return (
    <span
      className={clsx(
        "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
        {
          "bg-purple-50 text-purple-700 ring-purple-600/20": includes(
            [
              TaskStatus.GeneratingPr,
              TaskStatus.GeneratingBranch,
              TaskStatus.AddressingCodeReview,
              TaskStatus.TestingPr,
              TaskStatus.DoingInvestigation,
            ],
            status,
          ),
          "bg-neutral-50 text-neutral-700 ring-neutral-600/20": includes(
            [
              TaskStatus.FailedGeneratingPr,
              TaskStatus.FailedGeneratingBranch,
              TaskStatus.AddressingCodeReviewFailed,
              TaskStatus.FailedTestingPr,
              TaskStatus.PrClosed,
              TaskStatus.FailedInvestigation,
            ],
            status,
          ),
          "bg-yellow-50 text-yellow-700 ring-yellow-600/20": includes(
            [
              TaskStatus.PendingPr,
              TaskStatus.PendingBranch,
              TaskStatus.PendingTestingPr,
              TaskStatus.Unknown,
              TaskStatus.PendingInvestigation,
            ],
            status,
          ),
          "bg-green-50 text-green-700 ring-green-600/20": includes(
            [
              TaskStatus.PrGenerated,
              TaskStatus.BranchGenerated,
              TaskStatus.PrTested,
              TaskStatus.PrMerged,
              TaskStatus.CompletedInvestigation,
              TaskStatus.DraftPrGenerated,
            ],
            status,
          ),
        },
        className, // Apply the optional className prop
      )}
    >
      {join(split(status, "_"), " ")}
    </span>
  );
};

interface IProps {
  showConnectExternalTicketingBanner: boolean;
  taskStats: GetTaskStatsQuery["taskStats"];
  repos: GetReposQuery["repos"];
  tasks: GetTasksQuery["tasks"];
  openModal: () => void;
  retryTask: (id: string) => Promise<void>;
  testTask: (pullRequestId: string) => Promise<void>;
  deleteTask: (id: string) => Promise<void>;
  createPullRequestFromTask: (taskId: string) => Promise<string>;
}

export const IssuesTable = ({
  taskStats,
  repos,
  tasks,
  openModal,
  retryTask,
  testTask,
  deleteTask,
  createPullRequestFromTask,
  showConnectExternalTicketingBanner,
}: IProps) => {
  const { selectedClient, user } = useAppContext();
  const { showNotification } = useNotificationContext();
  const { taskId: taskIdUrlParam } = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  // Stores the ids of tasks that are currently loading (i.e. waiting for API to return a response when triggering a run)
  const [loadingTasks, setLoadingTasks] = useState<string[]>([]);

  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>(null);

  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState<boolean>(false);
  const [taskToDelete, setTaskToDelete] = useState<GetTasksQuery["tasks"][0]>(null);

  const [isRetryGenerationModalOpen, setIsRetryGenerationModalOpen] = useState<boolean>(false);
  const [retryGenerationTask, setRetryGenerationTask] = useState<GetTasksQuery["tasks"][0]>(null);

  // Pagination
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const totalTasks = tasks.length;
  const indexOfFirstTask = totalTasks === 0 ? 0 : (currentPage - 1) * itemsPerPage;
  const indexOfLastTask = totalTasks === 0 ? 0 : Math.min(currentPage * itemsPerPage, totalTasks);
  const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);

  // const totalPages = Math.ceil(totalTasks / itemsPerPage); shouldn't need this for now

  const previousButtonDisabled = currentPage === 1;
  const nextButtonDisabled = currentPage >= Math.ceil(tasks.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevCurrent) => prevCurrent + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevCurrent) => prevCurrent - 1);
  };

  // Set poll to update current time every minute, used to determine if we should disable the "Create issue" button
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 60000); // Update every minute

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [isNewIssueButtonDisabled, newIssueButtonTooltip] = useMemo(() => {
    if (
      taskStats.successfulGeneratePullRequestRuns >=
      getMaxSuccessfulTasks(selectedClient.subscriptionPlan)
    ) {
      return [
        true,
        "You've reached your limit for pull requests/branches. Please upgrade your plan to create more issues.",
      ];
    }

    if (size(repos) === 0) {
      return [true, "You must enable at least one GitHub repository to create an issue."];
    }

    const lastSyncedAt = Math.max(...repos.map((repo) => new Date(repo.lastSyncedAt).getTime()));
    const fiveMinutes = 1000 * 60 * 5;
    const isSyncing = currentTime - lastSyncedAt < fiveMinutes;

    if (isSyncing) {
      return [
        true,
        "Syncing repos may take up to 5 minutes. Please wait a few minutes before trying again.",
      ];
    }

    return [false, undefined];
  }, [repos, currentTime, taskStats, selectedClient.subscriptionPlan]);

  const remainingPullRequests =
    getMaxSuccessfulTasks(selectedClient.subscriptionPlan) -
    taskStats.successfulGeneratePullRequestRuns;

  // TEMP: Eventually we'll have a task drawer that will show the video, but for now we'll just open a modal so that we can link to this from a GitHub comment
  // TODO: update this to use the /app/task/:id route instead of the query params
  useEffect(() => {
    const taskId = searchParams.get("taskId");
    const action = searchParams.get("action");

    if (taskId && action === "view-testing-video") {
      const task = tasks.find((task) => task.id === taskId);
      const testingVideoUrl =
        task?.activeGeneratePullRequestRun?.activePullRequest?.activeTestingRun?.videoUrl;
      if (testingVideoUrl) {
        setVideoUrl(testingVideoUrl);
        setIsVideoModalOpen(true);
        searchParams.delete("taskId");
        searchParams.delete("action");
        setSearchParams(searchParams);
      }
    }
  }, [searchParams, tasks]);

  return (
    <>
      {showConnectExternalTicketingBanner && (
        <div className="mb-4">
          <InformationBanner>
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                Connect Tusk to your <span className="font-semibold">project management app</span>{" "}
                in{" "}
                <a
                  onClick={() => navigate(`/app/settings?client=${selectedClient.id}`)}
                  className="text-purple-600 hover:text-purple-800 cursor-pointer"
                >
                  Settings
                </a>{" "}
                to surface issues and assign them to Tusk.
              </div>
            </div>
          </InformationBanner>
        </div>
      )}
      {size(tasks) !== 0 &&
        (remainingPullRequests <= 5 || !isPaidPlan(selectedClient?.subscriptionPlan)) && (
          <div className="mb-8">
            <PullRequestLimitAlert
              isMonthly={isMonthlyPlan(selectedClient?.subscriptionPlan)}
              remainingPullRequests={remainingPullRequests}
              plan={getPlanName(selectedClient?.subscriptionPlan)}
            />
          </div>
        )}
      <div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Issues</h1>
            <p className="mt-2 text-sm text-gray-700">
              Track and manage your issues here.{" "}
              <a
                href="https://docs.usetusk.ai/best-practices"
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more{" "}
              </a>
              about best practices.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Button
              type="button"
              size="lg"
              onClick={openModal}
              disabled={isNewIssueButtonDisabled}
              tooltipText={newIssueButtonTooltip}
            >
              Create issue
            </Button>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Issue
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Tags
                  </th> */}
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Reporter
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Last updated
                    </th>
                    <th scope="col" className="relative py-3.5 pl-1 pr-1 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {currentTasks.map((task) => {
                    const taskStatus = getTaskStatus(task);

                    const viewAgentLogs = () => {
                      navigate(`/app/task/${task.id}`);
                    };

                    // for now allow user to retry generation anytime
                    const canRetryGeneration =
                      task.activeGeneratePullRequestRun &&
                      !includes(
                        [
                          TaskStatus.PendingPr,
                          TaskStatus.PendingBranch,
                          TaskStatus.GeneratingBranch,
                          TaskStatus.GeneratingPr,
                        ],
                        taskStatus,
                      );
                    const openRetryGenerationModal = canRetryGeneration
                      ? async () => {
                          setRetryGenerationTask(task);
                          setIsRetryGenerationModalOpen(true);
                        }
                      : undefined;

                    const canRetryTesting = includes([TaskStatus.FailedTestingPr], taskStatus);
                    const retryTesting = canRetryTesting
                      ? async () => {
                          const pullRequestId =
                            task?.activeGeneratePullRequestRun?.activePullRequest?.id;
                          if (!pullRequestId) {
                            showNotification({
                              title: "Unable to retry testing",
                              message:
                                "The pull request for this issue does not exist. Please try again.",
                            });
                            return;
                          }
                          setLoadingTasks((loadingTasks) => [...loadingTasks, task.id]);
                          await testTask(pullRequestId);
                          setLoadingTasks((loadingTasks) =>
                            loadingTasks.filter((id) => id !== task.id),
                          );
                          showNotification({
                            title: "Testing in progress ⚙️",
                            message:
                              "Tusk is now testing the pull request. You can view the testing video once it is complete.",
                          });
                        }
                      : undefined;

                    const pullRequestUrl =
                      task.activeGeneratePullRequestRun?.activePullRequest?.externalUrl;
                    const canViewPr = Boolean(pullRequestUrl);
                    const viewPullRequest = canViewPr
                      ? () => window.open(pullRequestUrl, "_blank")
                      : undefined;

                    const branchUrl = getGeneratePullRequestRunBranchUrl(task);
                    const canViewBranch = Boolean(branchUrl) && !canViewPr;
                    const viewBranch = canViewBranch
                      ? () => window.open(branchUrl, "_blank")
                      : undefined;

                    const testingVideoUrl =
                      task.activeGeneratePullRequestRun?.activePullRequest?.activeTestingRun
                        ?.videoUrl;
                    const canViewTestingVideo = Boolean(testingVideoUrl);
                    const viewTestingVideo = canViewTestingVideo
                      ? () => {
                          setVideoUrl(testingVideoUrl);
                          setIsVideoModalOpen(true);
                        }
                      : undefined;

                    // can only delete tasks if they are on a paid plan (or admin user)
                    // eventually we should only allow deleting tasks that are not merged (in theory user can game the system by deleting a task that is merged and then creating a new one)
                    const canDeleteTask =
                      isPaidPlan(selectedClient.subscriptionPlan) || user?.isAdmin;
                    const openDeleteTaskModal = canDeleteTask
                      ? async () => {
                          setTaskToDelete(task);
                          setIsDeleteTaskModalOpen(true);
                        }
                      : undefined;

                    const canCreatePullRequest =
                      !task.activeGeneratePullRequestRun?.activePullRequest &&
                      task.activeGeneratePullRequestRun?.branchName &&
                      user.isAdmin;
                    const createPullRequest = canCreatePullRequest
                      ? async () => {
                          try {
                            showNotification({
                              title: "Creating pull request ⚙️",
                              duration: 5000,
                            });
                            setLoadingTasks((loadingTasks) => [...loadingTasks, task.id]);
                            const pullRequestUrl = await createPullRequestFromTask(task.id);
                            showNotification({
                              title: "Created pull request 🧑‍💻",
                              message: `View pull request for "${task.metadata.title}" [here](${pullRequestUrl})`,
                            });
                          } catch (error: unknown) {
                            console.error("Error creating pull request", error);
                            if (isError(error) && isApolloError(error)) {
                              showNotification({
                                title: "Error creating pull request",
                                message: error.message,
                              });
                            } else {
                              showNotification({
                                title: "Error creating pull request",
                              });
                            }
                          } finally {
                            setLoadingTasks((loadingTasks) =>
                              loadingTasks.filter((id) => id !== task.id),
                            );
                          }
                        }
                      : undefined;

                    return (
                      <tr
                        key={task.id}
                        onClick={() => navigate(`/app/task/${task.id}`)}
                        className="cursor-pointer hover:bg-gray-100"
                      >
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm">
                          <div className="flex items-center">
                            <div className="ml-px">
                              <div className="font-medium text-gray-900 truncate overflow-hidden max-w-md">
                                {task.metadata.title}
                              </div>
                              <div className="mt-1 text-gray-500 max-w-md truncate overflow-hidden">
                                {task.metadata.description}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <IssueStatus status={taskStatus} />
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
                          Frontend
                        </span>
                      </td> */}
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="flex items-center">
                            {task.user ? (
                              <>
                                <div className="ml-2 flex-shrink-0">
                                  <div
                                    className={clsx(
                                      "h-8 w-8 rounded-full flex items-center justify-center text-white",
                                      getInitialsColor(task.user.name),
                                    )}
                                    title={task.user.name}
                                  >
                                    {getInitials(task.user.name)}
                                  </div>
                                </div>
                                <div className="ml-2">
                                  <div className="whitespace-nowrap py-5 text-sm text-gray-500">
                                    {task.user.name}
                                  </div>
                                </div>
                              </>
                            ) : task.externalTicketMetadata?.identifier &&
                              task.externalTicketMetadata?.url ? (
                              <div onClick={(event) => event.stopPropagation()}>
                                <a
                                  href={task.externalTicketMetadata.url}
                                  className="ml-2 text-sm text-purple-600 hover:text-purple-800"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {task.externalTicketMetadata.identifier}
                                </a>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          {new Date(task.updatedAt).toLocaleDateString("en-US", {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                          })}
                        </td>
                        <td className="relative whitespace-nowrap py-5 pl-1 pr-1 text-right text-sm font-medium sm:pr-0">
                          <div className="flex items-center">
                            <div onClick={(event) => event.stopPropagation()}>
                              <IssueOptions
                                viewAgentLogs={viewAgentLogs}
                                openRetryGenerationModal={openRetryGenerationModal}
                                retryGenerationDisabled={includes(loadingTasks, task.id)}
                                retryTesting={retryTesting}
                                retryTestingDisabled={includes(loadingTasks, task.id)}
                                viewPullRequest={viewPullRequest}
                                viewBranch={viewBranch}
                                viewTestingVideo={viewTestingVideo}
                                openDeleteTaskModal={openDeleteTaskModal}
                                createPullRequest={createPullRequest}
                                createPullRequestDisabled={includes(loadingTasks, task.id)}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {size(tasks) === 0 && (
                <div className="text-center mt-4">
                  <IssueEmptyState
                    onNewIssueClick={openModal}
                    buttonDisabled={isNewIssueButtonDisabled}
                    buttonTooltip={newIssueButtonTooltip}
                  />
                </div>
              )}
              <IssuesTablePagination
                totalTasks={totalTasks}
                indexOfFirstTask={indexOfFirstTask + 1}
                indexOfLastTask={indexOfLastTask}
                goToPreviousPage={goToPreviousPage}
                goToNextPage={goToNextPage}
                previousButtonDisabled={previousButtonDisabled}
                nextButtonDisabled={nextButtonDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <TaskSlideOver
        taskId={taskIdUrlParam}
        open={Boolean(taskIdUrlParam)}
        closeSlideover={() => navigate(`/app`)}
      />
      <VideoModal
        modalOpen={isVideoModalOpen}
        setModalOpen={setIsVideoModalOpen}
        videoUrl={videoUrl}
      />
      <DeleteTaskModal
        taskTitle={taskToDelete?.metadata?.title}
        open={isDeleteTaskModalOpen}
        setOpen={setIsDeleteTaskModalOpen}
        onDelete={async () => {
          await deleteTask(taskToDelete.id);
          setIsDeleteTaskModalOpen(false);
          setTaskToDelete(null);
          showNotification({
            title: "Issue deleted 🗑️",
            message: "The issue has been successfully deleted.",
          });
        }}
      />
      <RetryGenerationModal
        task={retryGenerationTask}
        open={isRetryGenerationModalOpen}
        setOpen={setIsRetryGenerationModalOpen}
        onRetry={async () => {
          setLoadingTasks((loadingTasks) => [...loadingTasks, retryGenerationTask.id]);
          await retryTask(retryGenerationTask.id);
          setLoadingTasks((loadingTasks) =>
            loadingTasks.filter((id) => id !== retryGenerationTask.id),
          );
          showNotification({
            title: "Code generation in progress ⚙️",
            message:
              "Tusk is now regenerating the pull request. You can view the pull request once it is complete.",
          });
          setIsRetryGenerationModalOpen(false);
          setRetryGenerationTask(null);
        }}
      />
    </>
  );
};
